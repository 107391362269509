@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.account-page-grid {
  margin-top: 40px;
  margin-bottom: 40px;

  & > .lysa-ui-row {
    gap: 38px 0;
  }
}

.account-page {
  .card-list,
  .card {
    margin: 0;
  }

  a.lysa-ui-button {
    text-decoration: none;
  }

  .page-header {
    padding: 30px 0 10px 0;

    h1 {
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .account-page-card-wrapper {
    @include media.tablet {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  .account-page-docs,
  .account-page-monthly,
  .account-page-allocation,
  .account-account-focus,
  .account-account-allocation,
  .account-page-pending-internal-transfers,
  .account-page-pension-account-focus,
  .account-page-pension-beneficiary,
  .account-page-fees-data,
  .account-page-fees-example,
  .account-page-transactions,
  .account-page-positions-data,
  .account-page-positions-example,
  .account-page-share-account,
  .account-page-insurance-info {
    .card-list {
      overflow: hidden;
    }

    .account-page-card-body {
      padding: 17px 16px;

      @include media.landscape {
        padding: 17px 22px;
      }
    }
  }

  .disclaimer {
    opacity: 0.5;
    font-size: 11px;
    line-height: 1.5;
    margin: 20px 14px 0 14px;

    @include media.tablet {
      margin: 20px 24px 0 24px;
      font-size: 13px;
    }
  }
}
