@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.pending-pension-withdrawal {
  margin-bottom: 44px;

  .list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;

    @include media.tablet {
      gap: 38px 40px;
    }

    @include media.desktop {
      gap: 38px 30px;
    }

    @include media.desktop-plus {
      gap: 38px 60px;
    }

    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include media.tablet {
        width: calc(50% - 20px);
      }

      @include media.desktop {
        width: calc(33.33% - 20px);
      }

      @include media.desktop-plus {
        width: calc(33.33% - 40px);
      }
    }

    .card-button {
      padding: 0;
      background-color: transparent;
      border: none;
      width: 100%;
      cursor: pointer;

      .card {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        .start {
          display: flex;
          align-items: center;
          overflow: hidden;
          gap: 1rem;

          .label {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            overflow: hidden;
          }

          .byline {
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            align-items: center;
            gap: 0.5rem;

            .icon {
              width: 1.8rem;
              height: auto;
              color: var(--lysa-text-secondary);
              margin-bottom: 0.2rem;
            }
          }
        }

        .end {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }

      .dot {
        width: 8px;
        height: 8px;
        min-width: 8px;
        min-height: 8px;
        background-color: var(--lysa-brand-blue);
        border-radius: 50%;
        display: inline-block;
        align-self: flex-start;
        margin: 0.8rem 0;
      }

      .chevron-right {
        display: flex;
        align-items: center;
      }

      .ellipsis {
        white-space: nowrap;
        line-clamp: 1;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;
      }

      &:active {
        opacity: 0.5;
      }

      .flex-horizontal {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
      }

      .flex-vertical {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
}
