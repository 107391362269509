.transfer-pension-done {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .success {
    margin-top: -8rem;
  }

  .header {
    margin-top: -6rem;
  }

  .ingress {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--lysa-text-secondary);
    margin-top: 0;
  }

  .divider {
    width: 100%;
    border-top: 1px solid var(--color-gray-200);
    margin: 2rem 0;
  }

  .cta {
    margin-top: 3rem;
  }
}
