button.user-card {
  display: block;
  width: 100%;

  .card-list {
    margin-bottom: 16px;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }

  .left-wrapper {
    display: flex;
    align-items: center;
  }

  .button-text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .list-name {
    font-weight: bold;
    color: black;
  }

  .list-name,
  .list-id {
    line-height: 18px;
  }
}
