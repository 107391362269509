@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.overview-pending {
  margin-bottom: 44px;

  .list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;

    @include media.tablet {
      gap: 38px 40px;
    }

    @include media.desktop {
      gap: 38px 30px;
    }

    @include media.desktop-plus {
      gap: 38px 60px;
    }

    .card-button {
      @include media.tablet {
        width: calc(50% - 20px);
      }

      @include media.desktop {
        width: calc(33.33% - 20px);
      }

      @include media.desktop-plus {
        width: calc(33.33% - 40px);
      }
    }
  }
}

.overview-pending-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 16px;
    }

    .button-container {
      margin-top: 12px;
    }
  }
}
